/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AnamneseOutput,
  ConversationCreatedResponse,
  ConversationRecord,
  ConversationRecordWithMessages,
  CreatePublicConversationUserAttributes,
  HTTPValidationError,
  PublicConversationRecordWithMessages,
  StartPublicConversationResponse,
} from '../models/index';
import {
    AnamneseOutputFromJSON,
    AnamneseOutputToJSON,
    ConversationCreatedResponseFromJSON,
    ConversationCreatedResponseToJSON,
    ConversationRecordFromJSON,
    ConversationRecordToJSON,
    ConversationRecordWithMessagesFromJSON,
    ConversationRecordWithMessagesToJSON,
    CreatePublicConversationUserAttributesFromJSON,
    CreatePublicConversationUserAttributesToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    PublicConversationRecordWithMessagesFromJSON,
    PublicConversationRecordWithMessagesToJSON,
    StartPublicConversationResponseFromJSON,
    StartPublicConversationResponseToJSON,
} from '../models/index';

export interface GetAnamneseRequest {
    conversationId: string;
    mamaDisease: string;
    mamaLanguage: string;
}

export interface GetAnswerConversationWithIdRequest {
    conversationId: string;
    mamaFrontendPath?: string;
    userAgent?: string;
}

export interface GetAnswerConversationsRequest {
    mamaDisease: string;
    mamaFrontendPath?: string;
    userAgent?: string;
}

export interface GetConversationWithIdRequest {
    conversationId: string;
    mamaFrontendPath?: string;
    userAgent?: string;
}

export interface GetConversationsRequest {
    mamaDisease: string;
    mamaFrontendPath?: string;
    userAgent?: string;
}

export interface GetMultiagentChatCompletenessRequest {
    conversationId: string;
    mamaDisease: string;
    mamaFrontendPath?: string;
    userAgent?: string;
}

export interface GetMultiagentMessageCountRequest {
    mamaDisease: string;
    mamaFrontendPath?: string;
    userAgent?: string;
}

export interface GetPublicConversationWithIdRequest {
    mamaUser: string;
    mamaConversationId: string;
    authorization: string;
}

export interface StartAnswerConversationRequest {
    mamaDisease: string;
    mamaLanguage: string;
    mamaFrontendPath?: string;
    userAgent?: string;
}

export interface StartConversationRequest {
    mamaDisease: string;
    mamaLanguage: string;
    mamaFrontendPath?: string;
    userAgent?: string;
}

export interface StartPublicConversationRequest {
    mamaDisease: string;
    mamaLanguage: string;
    createPublicConversationUserAttributes: CreatePublicConversationUserAttributes;
    entrypoint?: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Get Anamnese
     */
    async getAnamneseRaw(requestParameters: GetAnamneseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnamneseOutput>> {
        if (requestParameters.conversationId === null || requestParameters.conversationId === undefined) {
            throw new runtime.RequiredError('conversationId','Required parameter requestParameters.conversationId was null or undefined when calling getAnamnese.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getAnamnese.');
        }

        if (requestParameters.mamaLanguage === null || requestParameters.mamaLanguage === undefined) {
            throw new runtime.RequiredError('mamaLanguage','Required parameter requestParameters.mamaLanguage was null or undefined when calling getAnamnese.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaLanguage !== undefined && requestParameters.mamaLanguage !== null) {
            headerParameters['mama-language'] = String(requestParameters.mamaLanguage);
        }

        const response = await this.request({
            path: `/analysis/anamnese/{conversation_id}`.replace(`{${"conversation_id"}}`, encodeURIComponent(String(requestParameters.conversationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnamneseOutputFromJSON(jsonValue));
    }

    /**
     * Get Anamnese
     */
    async getAnamnese(requestParameters: GetAnamneseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnamneseOutput> {
        const response = await this.getAnamneseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Answer Conversation With Id
     */
    async getAnswerConversationWithIdRaw(requestParameters: GetAnswerConversationWithIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConversationRecordWithMessages>> {
        if (requestParameters.conversationId === null || requestParameters.conversationId === undefined) {
            throw new runtime.RequiredError('conversationId','Required parameter requestParameters.conversationId was null or undefined when calling getAnswerConversationWithId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaFrontendPath !== undefined && requestParameters.mamaFrontendPath !== null) {
            headerParameters['mama-frontend-path'] = String(requestParameters.mamaFrontendPath);
        }

        if (requestParameters.userAgent !== undefined && requestParameters.userAgent !== null) {
            headerParameters['user-agent'] = String(requestParameters.userAgent);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/answer/conversations/{conversation_id}`.replace(`{${"conversation_id"}}`, encodeURIComponent(String(requestParameters.conversationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConversationRecordWithMessagesFromJSON(jsonValue));
    }

    /**
     * Get Answer Conversation With Id
     */
    async getAnswerConversationWithId(requestParameters: GetAnswerConversationWithIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConversationRecordWithMessages> {
        const response = await this.getAnswerConversationWithIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Answer Conversations
     */
    async getAnswerConversationsRaw(requestParameters: GetAnswerConversationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConversationRecord>>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getAnswerConversations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaFrontendPath !== undefined && requestParameters.mamaFrontendPath !== null) {
            headerParameters['mama-frontend-path'] = String(requestParameters.mamaFrontendPath);
        }

        if (requestParameters.userAgent !== undefined && requestParameters.userAgent !== null) {
            headerParameters['user-agent'] = String(requestParameters.userAgent);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/answer/conversations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConversationRecordFromJSON));
    }

    /**
     * Get Answer Conversations
     */
    async getAnswerConversations(requestParameters: GetAnswerConversationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConversationRecord>> {
        const response = await this.getAnswerConversationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Conversation With Id
     */
    async getConversationWithIdRaw(requestParameters: GetConversationWithIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConversationRecordWithMessages>> {
        if (requestParameters.conversationId === null || requestParameters.conversationId === undefined) {
            throw new runtime.RequiredError('conversationId','Required parameter requestParameters.conversationId was null or undefined when calling getConversationWithId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaFrontendPath !== undefined && requestParameters.mamaFrontendPath !== null) {
            headerParameters['mama-frontend-path'] = String(requestParameters.mamaFrontendPath);
        }

        if (requestParameters.userAgent !== undefined && requestParameters.userAgent !== null) {
            headerParameters['user-agent'] = String(requestParameters.userAgent);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/conversation/conversations/{conversation_id}`.replace(`{${"conversation_id"}}`, encodeURIComponent(String(requestParameters.conversationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConversationRecordWithMessagesFromJSON(jsonValue));
    }

    /**
     * Get Conversation With Id
     */
    async getConversationWithId(requestParameters: GetConversationWithIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConversationRecordWithMessages> {
        const response = await this.getConversationWithIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Conversations
     */
    async getConversationsRaw(requestParameters: GetConversationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConversationRecord>>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getConversations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaFrontendPath !== undefined && requestParameters.mamaFrontendPath !== null) {
            headerParameters['mama-frontend-path'] = String(requestParameters.mamaFrontendPath);
        }

        if (requestParameters.userAgent !== undefined && requestParameters.userAgent !== null) {
            headerParameters['user-agent'] = String(requestParameters.userAgent);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/conversation/conversations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConversationRecordFromJSON));
    }

    /**
     * Get Conversations
     */
    async getConversations(requestParameters: GetConversationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConversationRecord>> {
        const response = await this.getConversationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Multiagent Chat Completeness
     */
    async getMultiagentChatCompletenessRaw(requestParameters: GetMultiagentChatCompletenessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.conversationId === null || requestParameters.conversationId === undefined) {
            throw new runtime.RequiredError('conversationId','Required parameter requestParameters.conversationId was null or undefined when calling getMultiagentChatCompleteness.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getMultiagentChatCompleteness.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaFrontendPath !== undefined && requestParameters.mamaFrontendPath !== null) {
            headerParameters['mama-frontend-path'] = String(requestParameters.mamaFrontendPath);
        }

        if (requestParameters.userAgent !== undefined && requestParameters.userAgent !== null) {
            headerParameters['user-agent'] = String(requestParameters.userAgent);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/completeness/percentage/{conversation_id}`.replace(`{${"conversation_id"}}`, encodeURIComponent(String(requestParameters.conversationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Multiagent Chat Completeness
     */
    async getMultiagentChatCompleteness(requestParameters: GetMultiagentChatCompletenessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getMultiagentChatCompletenessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Multiagent Message Count
     */
    async getMultiagentMessageCountRaw(requestParameters: GetMultiagentMessageCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getMultiagentMessageCount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaFrontendPath !== undefined && requestParameters.mamaFrontendPath !== null) {
            headerParameters['mama-frontend-path'] = String(requestParameters.mamaFrontendPath);
        }

        if (requestParameters.userAgent !== undefined && requestParameters.userAgent !== null) {
            headerParameters['user-agent'] = String(requestParameters.userAgent);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/completeness/message-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Multiagent Message Count
     */
    async getMultiagentMessageCount(requestParameters: GetMultiagentMessageCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getMultiagentMessageCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Public Conversation With Id
     */
    async getPublicConversationWithIdRaw(requestParameters: GetPublicConversationWithIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicConversationRecordWithMessages>> {
        if (requestParameters.mamaUser === null || requestParameters.mamaUser === undefined) {
            throw new runtime.RequiredError('mamaUser','Required parameter requestParameters.mamaUser was null or undefined when calling getPublicConversationWithId.');
        }

        if (requestParameters.mamaConversationId === null || requestParameters.mamaConversationId === undefined) {
            throw new runtime.RequiredError('mamaConversationId','Required parameter requestParameters.mamaConversationId was null or undefined when calling getPublicConversationWithId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getPublicConversationWithId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaUser !== undefined && requestParameters.mamaUser !== null) {
            headerParameters['mama-user'] = String(requestParameters.mamaUser);
        }

        if (requestParameters.mamaConversationId !== undefined && requestParameters.mamaConversationId !== null) {
            headerParameters['mama-conversation-id'] = String(requestParameters.mamaConversationId);
        }

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/public/conversations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicConversationRecordWithMessagesFromJSON(jsonValue));
    }

    /**
     * Get Public Conversation With Id
     */
    async getPublicConversationWithId(requestParameters: GetPublicConversationWithIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicConversationRecordWithMessages> {
        const response = await this.getPublicConversationWithIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Root
     */
    async rootRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alive`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Root
     */
    async root(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.rootRaw(initOverrides);
        return await response.value();
    }

    /**
     * Start Answer Conversation
     */
    async startAnswerConversationRaw(requestParameters: StartAnswerConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConversationCreatedResponse>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling startAnswerConversation.');
        }

        if (requestParameters.mamaLanguage === null || requestParameters.mamaLanguage === undefined) {
            throw new runtime.RequiredError('mamaLanguage','Required parameter requestParameters.mamaLanguage was null or undefined when calling startAnswerConversation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaLanguage !== undefined && requestParameters.mamaLanguage !== null) {
            headerParameters['mama-language'] = String(requestParameters.mamaLanguage);
        }

        if (requestParameters.mamaFrontendPath !== undefined && requestParameters.mamaFrontendPath !== null) {
            headerParameters['mama-frontend-path'] = String(requestParameters.mamaFrontendPath);
        }

        if (requestParameters.userAgent !== undefined && requestParameters.userAgent !== null) {
            headerParameters['user-agent'] = String(requestParameters.userAgent);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/answer/conversations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConversationCreatedResponseFromJSON(jsonValue));
    }

    /**
     * Start Answer Conversation
     */
    async startAnswerConversation(requestParameters: StartAnswerConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConversationCreatedResponse> {
        const response = await this.startAnswerConversationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start Conversation
     */
    async startConversationRaw(requestParameters: StartConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConversationCreatedResponse>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling startConversation.');
        }

        if (requestParameters.mamaLanguage === null || requestParameters.mamaLanguage === undefined) {
            throw new runtime.RequiredError('mamaLanguage','Required parameter requestParameters.mamaLanguage was null or undefined when calling startConversation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaLanguage !== undefined && requestParameters.mamaLanguage !== null) {
            headerParameters['mama-language'] = String(requestParameters.mamaLanguage);
        }

        if (requestParameters.mamaFrontendPath !== undefined && requestParameters.mamaFrontendPath !== null) {
            headerParameters['mama-frontend-path'] = String(requestParameters.mamaFrontendPath);
        }

        if (requestParameters.userAgent !== undefined && requestParameters.userAgent !== null) {
            headerParameters['user-agent'] = String(requestParameters.userAgent);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/conversation/conversations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConversationCreatedResponseFromJSON(jsonValue));
    }

    /**
     * Start Conversation
     */
    async startConversation(requestParameters: StartConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConversationCreatedResponse> {
        const response = await this.startConversationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start Public Conversation
     */
    async startPublicConversationRaw(requestParameters: StartPublicConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StartPublicConversationResponse>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling startPublicConversation.');
        }

        if (requestParameters.mamaLanguage === null || requestParameters.mamaLanguage === undefined) {
            throw new runtime.RequiredError('mamaLanguage','Required parameter requestParameters.mamaLanguage was null or undefined when calling startPublicConversation.');
        }

        if (requestParameters.createPublicConversationUserAttributes === null || requestParameters.createPublicConversationUserAttributes === undefined) {
            throw new runtime.RequiredError('createPublicConversationUserAttributes','Required parameter requestParameters.createPublicConversationUserAttributes was null or undefined when calling startPublicConversation.');
        }

        const queryParameters: any = {};

        if (requestParameters.entrypoint !== undefined) {
            queryParameters['entrypoint'] = requestParameters.entrypoint;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaLanguage !== undefined && requestParameters.mamaLanguage !== null) {
            headerParameters['mama-language'] = String(requestParameters.mamaLanguage);
        }

        const response = await this.request({
            path: `/public/start-conversation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePublicConversationUserAttributesToJSON(requestParameters.createPublicConversationUserAttributes),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StartPublicConversationResponseFromJSON(jsonValue));
    }

    /**
     * Start Public Conversation
     */
    async startPublicConversation(requestParameters: StartPublicConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StartPublicConversationResponse> {
        const response = await this.startPublicConversationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
